.date-picker {
  border-radius: 5px !important;
  border-color: var(--grey3) !important;
}

.label-container {
  position: relative;
}

.label-container > label {
  position: absolute;
  top: -9px;
  left: 15px;
  background: var(--white);
  font-size: 10px !important;
  color: var(--grey5) !important;
  z-index: 1;
  padding: 0 2px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
}

.colored-label {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 46%,
    rgba(248, 250, 252, 1) 46%
  ) !important;
}

.separator-arrow {
  color: var(--grey5);
  font-size: 12px;
}
