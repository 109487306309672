.add-operation-modal {
  display: grid;  
  grid-template-columns: repeat(3, 11rem);
  max-width: 36rem;
  gap: 0.75rem;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.auto-fit {
  grid-template-columns: repeat(auto-fit, 11rem) !important;
}

.operation-option-container {
  border: 1px solid var(--grey3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 11rem;
  gap: 0.5rem;
  padding: 1rem;
  cursor: pointer;
}

.operation-option-container:hover {
  border-color: var(--primary-color);
  background-color: var(--green2);
}

.operation-option-header-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.operation-icon {
  background: var(--green3);
  color: var(--primary-text-color);
  padding: 0.25rem;
  border-radius: 2px;
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: var(--grey5);
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}
