.row-height-big {
  height: 7rem !important;
}

.row-height {
  height: 4.5rem !important;
}

.classification-wrapper {
  display: flex;
  flex-direction: column;
}

.progress-bar {
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}

.classify-margin-bottom {
  margin-bottom: 1rem !important;
}

.arrow {
  color: var(--grey4) !important;
}

.description {
  white-space: break-spaces !important;
}

.textarea {
  height: auto !important;
}

.content {
  margin-top: 2rem;
}

.information-container {
  color: var(--grey5);
  margin-left: 2rem;
}

.information-header h4 {
  color: var(--grey5);
}

.information-container > a {
  color: var(--grey5);
  margin-top: 0.5rem;
  display: inline-block;
}

.information-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.information-header :first-child {
  margin-right: 0.5rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
