.content-container {
  height: 30rem;
  background: var(--grey1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.header-container {
  border-bottom: 1px solid var(--grey4);
  background: var(--grey1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.divider {
  border-bottom: 1px solid var(--grey2);
  width: 100%;
}

.stream-preview {
  background: var(--grey2);
  overflow-x: scroll;
  overflow-y: scroll;
  height: 30rem;
  border-bottom-left-radius: 5px;
}

.content-wrapper {
  border-left: 1px solid var(--grey4);
  padding: 2rem 1rem;
}

.select-margin {
  margin-bottom: 0.5rem !important;
}

.checkbox {
  font-weight: bold;
}

.text-margin {
  margin-bottom: 1rem !important;
}

.header-margin {
  margin-left: 1.5rem;
}
