.container {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1300 !important;
  background: var(--grey1);
  transition: 0.5s;
}

.hidden-container {
  top: 130vh !important;
}

.progress {
  display: flex !important;
  height: 4px !important;
}

/* HeaderBar styling */
.header-bar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey3);
  padding: 0.75rem 2rem;
  width: 100%;
  height: 3.5rem;
}

.close-button {
  display: flex !important;
  align-items: center;
}

.text-area {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.button-stepper-container {
  display: flex !important;
  justify-content: flex-end;
  gap: 0.5rem;
}

.icon-text-container {
  display: flex;
  color: var(--white);
  align-items: center;
  gap: 0.5rem;
}

.icon-text-back {
  color: var(--primary-text-color);
}

/* SideBar styling */
.side-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30vw;
  background: var(--white);
  overflow-y: scroll;
}

.visible-overlay {
  position: absolute;
  top: calc(3.5rem + 4px);
  left: 0;
  height: calc(100vh - 3.5rem - 4px);
  z-index: 1;
  transition: 0.5s;
}

.hidden-overlay {
  position: absolute;
  top: calc(3.5rem + 4px);
  left: -35vw;
  height: calc(100vh - 3.5rem - 4px);
  z-index: 1;
  transition: 0.5s;
}

.side-bar-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3rem;
  border-bottom: 1px solid var(--grey3);
}

.side-bar-content-container {
  height: 90vh;
  overflow-y: auto;
}

.side-bar-content {
  padding: 2rem 1.5rem 0 3rem;
}

.side-bar-actions {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--grey3);
  padding: 1.5rem;
}
