.content {
  padding: 1.5rem;
  padding-bottom: 2rem;
}

.content-header-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem !important;
  gap: 0.5rem;
}

.fa-icon {
  color: var(--primary-color);
  font-size: 24px;
}

.container {
  background: var(--grey1);
  padding-bottom: 0rem !important;
  border-radius: 0.313rem;
}

.md {
  width: 46rem !important;
}

.lg {
  width: 66rem !important; /* Can be changed, currently not used */
}

.fa-icon-warning {
  color: var(--warning) !important;
}

.ok-warning-button-color {
  background-color: var(--red5) !important;
}

.ok-warning-button-color:hover {
  background-color: var(--red4) !important;
}

.cancel-warning-button-color {
  border-color: var(--green6) !important;
  color: var(--green6) !important;
}

.cancel-warning-button-color:hover {
  border-color: var(--green5) !important;
  color: var(--green5) !important;
}
