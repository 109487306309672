.slide-wrapper {
  position: relative;
  height: 250%;
  animation: slide 18s ease infinite;
  transform: translateY(-10%);
}

.slide {
  width: 100%;
  height: 20%;
}

/* @-webkit-keyframes slide { */
@keyframes slide {
  20% {
    transform: translateY(-10%);
  }
  30% {
    transform: translateY(-30%);
  }
  50% {
    transform: translateY(-30%);
  }
  60% {
    transform: translateY(-50%);
  }
  70% {
    transform: translateY(-50%);
  }
  80% {
    transform: translateY(-70%);
  }
  90% {
    transform: translateY(-70%);
  }
}
