.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item-container {
  display: flex;
  padding: 0.25rem 1rem;
  align-items: center;
  justify-content: space-between;
}

.item-container:hover {
  background: var(--primary1);
}

.enabled {
  color: var(--primary-text-color) !important;
}

.active-container {
  background: var(--primary1);
}

.icon {
  font-size: 10px;
  color: var(--grey5);
}

.text {
  color: var(--grey5);
}

.active-container > .text,
.active-container > .icon {
  color: var(--grey6);
}

.item-container-right {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}
