.container {
  background: var(--white);
  padding-bottom: 0rem !important;
  border-radius: 0.313rem;
}

.footer-container {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--grey3);
}

.margin-48 {
  margin-bottom: 48px !important;
}

.content-container {
  padding: 1.5rem 1.5rem 0 1.5rem;
}
