/* Operations styling */
.container {
  height: calc(64vh + 1rem);
}

.operations-add-area {
  height: 6rem;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* ManageOperations styling */
.manage-operation {
  padding: 0;
}

/* OperationCard styling */
.operation-card-container {
  border: 1px solid var(--grey3);
  border-left: 1rem solid var(--grey3);
  border-radius: 5px;
  padding: 0.75rem 0;
  position: relative;
}

.operation-card-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.remove-icon {
  cursor: pointer;
  color: var(--grey5);
  margin-right: 1rem;
}

.remove-icon:hover {
  color: var(--grey4);
}

.label-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-bottom: 1px solid var(--grey3);
  padding: 0.25rem 0.75rem;
}

.show-more-button {
  padding-left: 0.75rem;
}

.operation-card-attribute-container {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Arrow styling */
.arrow-container {
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  position: absolute;
  top: calc(100% + 0.35rem);
  left: 45%;
}

.line {
  width: 0.75rem;
  height: 2px;
  background: var(--primary-text-color);
}

.circle {
  height: 0.75rem;
  width: 0.75rem;
  background-color: var(--primary-text-color);
  border-radius: 50%;
  margin-right: 1px;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 0.5rem solid transparent;
  border-left: 0.75rem solid var(--primary-text-color);
  border-bottom: 0.5rem solid transparent;
  margin-left: 2px;
}

.empty-box {
  background: var(--white);
  width: 3px;
  height: 5px;
  position: relative;
  left: 4px;
  top: -1px;
}
