@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

/* remove list resets */
.ul {
  list-style-type: disc !important;
  list-style-position: inside !important;
}
.ol {
  list-style-type: decimal !important;
  list-style-position: inside !important;
}
.ul .ul,
.ol .ul {
  list-style-type: circle !important;
  list-style-position: inside !important;
  margin-left: 15px !important;
}
.ol .ol,
.ul .ol {
  list-style-type: lower-latin !important;
  list-style-position: inside !important;
  margin-left: 15px !important;
}

a.bold {
  font-weight: bold;
  color: var(--grey10);
}

small {
  font-size: 12px !important;
  line-height: normal;
}

a.small {
  font-size: 12px !important;
}

small.tiny {
  font-size: 10px !important;
}

.black {
  color: var(--grey10);
}

.uppercase {
  text-transform: uppercase !important;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold !important;
}

.italic {
  font-style: italic !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.inherit {
  color: inherit !important;
}

.primary {
  color: var(--primary-color);
}

span.red,
p.red,
small.red,
svg.red {
  color: var(--red6) !important;
}

.hidden {
  display: none !important;
}

.disabled {
  cursor: default !important;
}

.width-100 {
  width: 100%;
}

/* Responsive wrapper */
.outer-container {
  padding-right: 2rem;
  padding-left: 2rem;
}

.default-cursor {
  cursor: default;
}

.margin-left-12 {
  margin-left: 12px;
}

.main-table-container {
  width: 100%;
  border-radius: 5px;
  padding: 0;
  margin-top: 0.75rem;
}

.clickable {
  cursor: pointer;
}

.col-title {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--grey10);
  margin: 0;
}

.col-50-percent {
  width: 49%;
}

.col-32-percent {
  width: 30.3%;
}

.col-25-percent {
  width: 23%;
}

.col-4-percent {
  width: 3.9%;
}

.flex {
  display: flex;
}

.warning-modal-description {
  margin-top: 3rem;
}

.col-flex-end {
  grid-row-gap: 0.938rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flex-horizontal-gap {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.flex-vertical-gap {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}

.add-property {
  width: fit-content;
  display: flex;
  margin-bottom: 1.688rem;
  align-items: center;
  cursor: pointer;
}

.action-button-container {
  display: flex;
  padding: 1rem;
  gap: 0.25rem;
}

.round-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 4px;
  background: var(--grey5);
}

.add-round-icon {
  color: var(--grey1);
  font-size: 12px;
}

.create-icon {
  margin-right: 0.5rem;
}

.loading {
  font-size: 30;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  border-bottom: 1px solid var(--grey3) !important;
  width: 100%;
}

.vertical-divider {
  border: 1px solid var(--grey3);
}

.full-page-divider {
  border-bottom: 1px solid var(--grey3) !important;
  width: 100vw;
  position: relative;
  left: -2rem;
}

.data-type-icon-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.collect {
  color: var(--collect) !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.form-submit {
  float: right;
}

/* Overrides antd * on form item. */
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: var(--primary-text-color) !important;
  font-family: "IBM Plex Sans" !important;
}

/* Overrides antd error message on form item. */
.ant-form-item-explain.ant-form-item-explain-error {
  color: var(--warning) !important;
  font-weight: bold;
  font-size: 12px;
  margin-top: 0.25rem;
}

.ant-message-notice-content {
  border-radius: 5px !important;
}

.ant-spin.ant-spin-show-text {
  color: var(--primary-text-color);
}

.ant-popover-title {
  color: var(--grey5) !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

/*ant checkbox styling*/
.ant-checkbox-disabled,
.ant-checkbox-disabled .ant-checkbox-input,
.ant-checkbox-disabled + span {
  cursor: default;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0 !important;
}

/*ant select search input styling*/
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 100%;
}

.ant-spin-lg .ant-spin-dot i {
  background: var(--primary-color) !important;
}

.ant-spin .ant-spin-dot i {
  background: var(--primary-color) !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(—green1) !important;
}

/*antd pagination*/
.ant-pagination {
  gap: 0.25rem !important;
}

.ant-pagination-item {
  border-color: var(--grey3);
}

.ant-pagination-item-active:hover {
  border-color: var(--grey3);
  color: var(--grey3);
}

.ant-pagination-item-link {
  border-color: var(--grey3) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: var(--grey5) !important;
  background: var(--white) !important;
  cursor: pointer;
}

.ant-pagination-disabled {
  cursor: default !important;
}

.ant-pagination-disabled .ant-pagination-item-link {
  cursor: default !important;
  border-color: var(--grey3) !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: var(--grey5) !important;
  cursor: not-allowed !important;
  border-color: var(--grey5) !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover {
  border-color: var(--grey5) !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper:hover,
.ant-checkbox-input:focus .ant-checkbox-inner {
  border-color: transparent !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--white) !important;
}

/* antd dropdown */
.ant-dropdown-menu-submenu-popup {
  z-index: 2001 !important;
}

/* antd empty */
.ant-empty-description {
  color: var(--grey5);
}

.rc-textarea:not(:empty) {
  font-weight: 600 !important;
  color: var(--primary-text-color);
}

/* .react-resizable-handle {
  background-image: none !important;
  padding: 0;
  pointer-events: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s,
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne,
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw,
.react-grid-item > .react-resizable-handle.react-resizable-handle-se,
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  transform: none;
  margin: 0;
  cursor: nwse-resize !important;
  padding: 1.5rem 1.5rem;
  z-index: 2007 !important;
  position: absolute;
  right: 0;
  bottom: 0;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  opacity: 0;
}

.react-grid-placeholder {
  background-color: var(--green6) !important;
  opacity: 0.4;
} */
