.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--green2);
  width: fit-content;
  padding: 0.25rem 0.75rem;
  color: var(--primary-text-color);
  border-left: 2px solid var(--primary-text-color);
  border-radius: 0 2px 2px 0;
}

.icon {
  font-size: 14px;
}

.header {
  font-size: 14px;
  font-weight: 500;
}
