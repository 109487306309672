/* ManageOperations styling */
.manage-operation {
  padding: 0;
}

.manage-operation-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.manage-operation-type {
  border-bottom: 1px solid var(--grey3);
  padding: 2rem;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
}

.manage-operation-content {
  overflow: auto;
}

.manage-operation-add-area {
  height: 5rem;
  margin-top: 1rem;
}

.no-padding {
  padding: 0 !important;
}

/* FilterOperation styling */
.filter-operation-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--grey1);
  padding: 0.75rem;
}

.filter-operation-date-picker {
  height: 2rem !important;
  width: 100%;
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 0.5rem;
  color: var(--grey5);
}

.close-icon {
  cursor: pointer;
}

.close-icon:hover {
  color: var(--grey4);
}

.operation-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}

.filter-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.attribute-label {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

/* ColumnsOperation styling */
.column-operation-container {
  border-bottom: 1px solid var(--grey3);
  padding: 2rem 1.5rem;
}

.form-item {
  margin: 0 !important;
  padding: 0 !important;
}

.info-icon {
  font-size: 12px;
  color: var(--grey5);
  margin-right: 0.25rem;
}

.operation-section {
  width: 100%;
  padding: 0.75rem;
  background: var(--grey1);
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
}

.select-label {
  margin-left: 0.25rem;
}

.replace-select-options {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.replace-value-toggle {
  margin-bottom: 0.5rem;
}
