.lock {
  height: 2.5rem !important;
  margin-left: 0.5rem;
}

/* EditSlug styling */
.edit-slug-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.edit-slug-input {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.slug-width-limit {
  width: fit-content !important;
}

/* EditEndpointObject styling */
.attribute-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.attribute-wrapper {
  background: var(--grey1);
  border-top: 1px solid var(--grey3);
  padding: 1rem 0.75rem 0 0.75rem;
  margin-bottom: 1.5rem;
}

.attribute-information {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.attribute-information-text {
  display: flex;
  flex-direction: column;
}
