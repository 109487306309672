.text-field-container {
  border: 1px solid var(--grey3);
  border-radius: 5px;
  padding: 0 0.75rem;
  background-color: var(--white);
  width: 100%;
  position: relative;
  height: 2.5rem;
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.text-field-container > label {
  position: absolute;
  top: -9px;
  left: 15px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 53%,
    rgba(248, 250, 252, 1) 53%
  );
  font-size: 10px !important;
  color: var(--grey5) !important;
}

.text-field-container > p {
  border: none;
}

.hidden-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.eye-icon {
  color: var(--grey6);
  cursor: pointer;
}

.eye-icon:hover {
  color: var(--grey5);
}
