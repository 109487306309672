.label-with-icon {
  display: flex;
  align-items: center;
}

.icon {
  color: var(--primary-color);
  margin-right: 0.5rem;
}

.label-container {
  position: relative;
}

.label-container > label {
  position: absolute;
  top: -9px;
  left: 15px;
  background: var(--white);
  font-size: 10px !important;
  color: var(--grey5) !important;
  z-index: 1;
  padding: 0 2px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
}

.lock {
  height: 2.5rem !important;
}

.colored-label {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 46%,
    rgba(248, 250, 252, 1) 46%
  ) !important;
}
