:root {
  /* Gråskala */
  --grey1: #f8fafc;
  --grey2: #f1f5f9;
  --grey3: #e2e8f0; /* Use for disabled buttons */
  --grey4: #cbd5e1;
  --grey5: #94a3b8;
  --grey6: #64748b;
  --grey7: #475569;
  --grey8: #334155;
  --grey9: #1e293b;
  --grey10: #0f172a;

  /* Red */
  --red1: #fef2f2;
  --red2: #fee2e2;
  --red3: #fecaca;
  --red4: #fca5a5;
  --red5: #f87171;
  --red6: #ef4444;
  --red7: #dc2626;
  --red8: #b91c1c;
  --red9: #991b1b;
  --red10: #7f1d1d;

  /* Cyans */
  --cyan1: #ecfeff;
  --cyan2: #cffafe;
  --cyan3: #a5f3fc;
  --cyan4: #67e8f9;
  --cyan5: #22d3ee;
  --cyan6: #06b6d4;
  --cyan7: #0891b2;
  --cyan8: #0e7490;
  --cyan9: #115e75;
  --cyan10: #164e63;

  /* Orange */
  --orange1: #fff7ed;
  --orange2: #ffedd5;
  --orange3: #fed7aa;
  --orange4: #fdba74;
  --orange5: #fb923c;
  --orange6: #f97316;
  --orange7: #ea580c;
  --orange8: #c2410c;
  --orange9: #9a3412;
  --orange10: #7c2d12;

  /* Indigo */
  --indigo1: #eff2ff;
  --indigo2: #e0e7ff;
  --indigo3: #c7d2fe;
  --indigo4: #a5b4fc;
  --indigo5: #818cf8;
  --indigo6: #6366f1;
  --indigo7: #4f46e5;
  --indigo8: #4338ca;
  --indigo9: #3730a3;
  --indigo10: #312e81;

  /* Pink */
  --pink1: #fdf2f8;
  --pink2: #fce7f3;
  --pink3: #fbcfe8;
  --pink4: #f9a8d4;
  --pink5: #f472b6;
  --pink6: #ec4899;
  --pink7: #db2777;
  --pink8: #be185d;
  --pink9: #9d174d;
  --pink10: #831843;

  /* Purple */
  --purple1: #faf5ff;
  --purple2: #f3e8ff;
  --purple3: #e9d5ff;
  --purple4: #d8b4fe;
  --purple5: #c084fc;
  --purple6: #a855f7;
  --purple7: #9333ea;
  --purple8: #7e22ce;
  --purple9: #6b21a8;
  --purple10: #581c87;

  /* Blue */
  --blue1: #eff6ff;
  --blue2: #dbeafe;
  --blue3: #bfdbfe;
  --blue4: #93c5fd;
  --blue5: #60a5fa;
  --blue6: #3b82f6;
  --blue7: #2563eb;
  --blue8: #1d4ed8;
  --blue9: #1e40af;
  --blue10: #1e3a8a;

  /* Teal */
  --teal1: #f0fdfa;
  --teal2: #ccfbf1;
  --teal3: #99f6e4;
  --teal4: #5eead4;
  --teal5: #2dd4bf;
  --teal6: #14b8a6;
  --teal7: #0d9488;
  --teal8: #0f766e;
  --teal9: #115e59;
  --teal10: #134e4a;

  /* Yellow */
  --yellow1: #fffbeb;
  --yellow2: #fef3c7;
  --yellow3: #fde68a;
  --yellow4: #fcd34d;
  --yellow5: #fbbf24;
  --yellow6: #f59e0b;
  --yellow7: #d97706;
  --yellow8: #b45309;
  --yellow9: #92400e;
  --yellow10: #78350f;

  /* Lime */
  --lime1: #f7fee7;
  --lime2: #ecfccb;
  --lime3: #d9f99d;
  --lime4: #bef264;
  --lime5: #a3e635;
  --lime6: #84cc16;
  --lime7: #65a30d;
  --lime8: #4d7c0f;
  --lime9: #3f6212;
  --lime10: #365314;

  /* Green */
  --green1: #f0fdf4;
  --green2: #dcfce7;
  --green3: #bbf7d0;
  --green4: #86efac;
  --green5: #4ade80;
  --green6: #22c55e;
  --green7: #16a34a;
  --green8: #15803d;
  --green9: #166534;
  --green10: #14532d;
  --rgb-green5: 74, 222, 128;

  /* Emerald */
  --emerald1: #ecfdf5;
  --emerald2: #d1fae5;
  --emerald3: #a7f3d0;
  --emerald4: #6ee7b7;
  --emerald5: #34d399;
  --emerald6: #10b981;
  --emerald7: #059669;
  --emerald8: #047857;
  --emerald9: #065f46;
  --emerald10: #064e3b;

  /* Fuschsia */
  --fuschsia1: #fdf4ff;
  --fuschsia2: #fae8ff;
  --fuschsia3: #f5d0fe;
  --fuschsia4: #f0abfc;
  --fuschsia5: #e879f9;
  --fuschsia6: #d946ef;
  --fuschsia7: #c026d3;
  --fuschsia8: #a21caf;
  --fuschsia9: #86198f;
  --fuschsia10: #701a75;

  /* Produktfärger */
  --build: var(--green5);
  --cast: var(--green5);
  --collect: var(--green5);
  --shape: var(--green5);

  --build-hover: var(--green4);
  --cast-hover: var(--green4);
  --collect-hover: var(--green4);
  --shape-hover: var(--green4);

  --primary1: var(--green1);
  --primary2: var(--green2);
  --primary3: var(--green3);
  --primary4: var(--green4);
  --primary5: var(--green5);
  --primary6: var(--green6);
  --primary7: var(--green7);
  --primary8: var(--green8);
  --primary9: var(--green9);
  --primary10: var(--green10);

  /* Primära färger */
  --primary-color: var(--green6);
  --primary-color-hover: var(--green5);
  --primary-text-color: var(--green6);
  --secondary-color: var(--green3);
  --white: #fff;

  /* Övrigt */
  --warning: var(--red6);
  --warning-hover: var(--red5);

  /* grey10 som rgb */
  --shadow: rgba(15, 23, 42, 0.12);
}
