.toggle {
  display: flex;
  width: 100%;
}

.toggle > .selected {
  border-bottom: 2px solid var(--primary-color);
}

.toggle p {
  cursor: pointer;
  margin-right: 1.5rem;
}

.toggle p:hover {
  border-bottom: 2px solid var(--primary-color);
}

.column-padding {
  padding-left: 1.5rem !important;
}

.outer-border {
  border: 1px solid var(--grey3);
}

.top-border {
  border-top: 1px solid var(--grey3);
}

.no-top-border {
  border-top: none !important;
}

.thick-header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: white;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.expand-icon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.sort-button {
  font-size: 10px;
}

.sort-title-container {
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
}

.clickable-row-margin {
  margin-right: 2rem !important;
}
