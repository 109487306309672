.read-table-toggle {
  width: 30%;
}

.read-table-wrapper {
  margin-bottom: 1rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
}
