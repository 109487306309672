.container {
  display: flex;
  width: 100vw;
  height: calc(100vh - 3.5rem);
  width: 100vw;
  background: var(--grey1);
}

.transition-container {
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  transition: 0.5s;
}

.hidden-transation-container {
  top: 130vh !important;
}

.left-content {
  display: flex;
  background: var(--white);
  height: calc(100vh - 3.5rem);
  width: 30vw;
  border-right: 1px solid var(--grey3);
}

.transition-left-content {
  height: 100vh;
}

.right-content {
  width: 70vw;
  padding: 2rem 3rem;
}
