.container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey3);
  padding: 0 3rem;
}

.header {
  border-right: 1px solid var(--grey3);
  padding: 1rem 0.75rem;
}

.meta-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
}

.icon {
  color: var(--grey5);
  font-size: 20px;
  cursor: pointer;
}

/* Toggle styling */
.toggle-options {
  display: flex;
  margin-right: 1rem;
}

.toggle-header-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  border-bottom: 3px solid var(--grey1);
  color: var(--grey5);
  cursor: pointer;
}

.toggle-header {
  color: inherit;
  font-weight: 500;
}

.active-toggle {
  background-color: var(--grey2);
  color: var(--primary-text-color) !important;
  border-bottom: 3px solid var(--primary-color);
}
