.container {
  width: 100%;
  margin-top: 2rem;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.table {
  margin-top: 1rem;
}

.attribute-modal-actions {
  display: flex;
  gap: 0.5rem;
  margin: 1rem 1.5rem;
}

.attribute-modal-other {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
}

.attribute-modal-form-item {
  margin-bottom: 0rem !important;
}

.attribute-modal-form-row {
  margin-bottom: 1rem !important;
}

.lock-container {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--grey4);
  border-left: none !important;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey2);
}

.radio-container {
  display: flex !important;
  flex-direction: column !important;
  gap: 0.25rem;
}

.manual-input-container {
  margin-top: 1rem !important;
}

.label-with-information-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--grey5);
}

.missing-key-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
