.row-height-big {
  height: 7rem !important;
}

.row-height {
  height: 4.5rem !important;
}

.classification-wrapper {
  display: flex;
  flex-direction: column;
}

.progress-bar {
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}

.arrow {
  color: var(--grey4) !important;
}

.description {
  white-space: break-spaces !important;
}

.textarea {
  height: auto !important;
}

.classify-margin-bottom {
  margin-bottom: 1rem !important;
}
