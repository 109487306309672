.container {
  width: 100%;
  height: 1rem;
  background: var(--primary2);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.small-container {
  width: 2rem;
  height: 0.25rem;
  background: var(--primary2);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.medium-container {
  width: 100%;
  height: 0.5rem;
  background: var(--primary2);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.fill {
  background: var(--primary-color);
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid var(--grey1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fill-text-outer {
  display: inline-block !important;
}

.text {
  color: var(--grey1);
  margin-right: 0.5rem;
  font-weight: bold;
}

.text-outer {
  color: var(--primary-text-color);
  margin-left: 0.5rem;
  font-weight: bold;
}

.fill-100 {
  border-radius: 5px;
}
