/* sets background color of table header */
thead[class*='ant-table-thead'] th {
  background-color: var(--grey1) !important;
  color: var(--grey5) !important;
}

.ant-table-content > table {
  border: none !important;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid var(--grey3) !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-right: 1px solid var(--grey3) !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > .ant-table-cell:last-of-type {
  border-right: 0px solid var(--grey3) !important;
  border-right: none !important;
  border-right-width: 0 !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > .ant-table-cell:last-of-type {
  border-right: 0px solid var(--grey3) !important;
  border-right: none !important;
  border-right-width: 0 !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  padding-right: 2rem;
}

.ant-table-tbody > tr td:last-child {
  padding-right: 2rem;
}

.ant-table-cell {
  white-space: nowrap;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-bottom: 0 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: var(--grey2) !important;
}

/* removes seperator on table headers */
thead[class*='ant-table-thead'] th::before {
  display: none;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey3) !important;
}

.ant-table-thead > tr > th {
  border-bottom: 1px solid var(--grey3) !important;
}

.ant-checkbox-inner {
  border-radius: 5px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--primary-color) !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper:hover,
.ant-checkbox-input:focus .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
  border-radius: 5px !important;
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover::after {
  border-color: var(--primary-color-hover) !important;
  border-radius: 5px !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--green3) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color-hover) !important;
}

.ant-radio-inner::after {
  background-color: var(--primary-color-hover) !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary-color-hover) !important;
}

.ant-radio-checked::after {
  border: var(--primary-color-hover) !important;
}

.ant-table.ant-table-middle {
  border: 1px solid var(--grey3) !important;
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td {
  border-bottom: none !important;
  border-top: 1px solid var(--grey3) !important;
}

.ant-table.ant-table-middle .ant-table-tbody > .no-border > td {
  border-bottom: none !important;
  border-top: none !important;
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td:first {
  border-top: none !important;
}

.ant-table.ant-table-middle .ant-table-thead > tr > th {
  border-bottom: none !important;
}

.ant-table-summary {
  background-color: var(--grey2) !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--primary-color) !important;
}

.ant-table-expanded-row > .ant-table-cell {
  background: var(--grey2);
  padding: 0 !important;
}

.ant-table-expanded-row:hover > .ant-table-cell:hover {
  background-color: var(--grey2);
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--green1) !important;
}

.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}
