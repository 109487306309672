.outer-container {
  margin-top: 3rem;
}

.container {
  border-top: 1px solid var(--grey4);
  width: 100%;
  display: flex;
  background-color: var(--white);
  overflow: scroll;
  position: relative;
}

.edit-container {
  border: 2px solid var(--build);
}

.inner-container {
  display: flex;
}

.counter-container {
  height: 2rem;
  width: 2rem;
  background-color: var(--grey2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--grey4);
  font-size: 0.75rem;
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  color: var(--grey6);
  border-bottom: 1px solid var(--grey4);
}

.counter-container-selected {
  background-color: var(--grey3);
}

.counter-container-not-last {
  border-bottom: 1px solid var(--grey4);
}

.column-container {
  display: flex;
  flex-direction: column;
  min-width: 7rem;
  max-width: 14rem;
  width: 100%;
}

.column-item-container {
  height: 2rem;
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding-left: 0.75rem;
  padding-right: 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid var(--grey4);
  border-right: 1px solid var(--grey4);
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  font-size: 0.625rem;
  cursor: pointer;
  color: var(--grey6);
}

.column-item-container-selected {
  background-color: var(--grey3);
}

.column-item-container-selected-date {
  width: 7rem;
}

.row-item-container {
  white-space: nowrap;
  height: 2rem;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-right: 1px solid var(--grey4);
  caret-color: var(--build);
  border-bottom: 1px solid var(--grey4);
}

.row-item-selected-position {
  position: relative;
}

.row-item-selected {
  white-space: nowrap;
  display: flex;
  align-items: center;
  background-color: var(--white);
  --antd-wave-shadow-color: none !important;
  outline: none !important;
  --bs-body-color: none !important;
  border: 2px solid var(--build);
  box-sizing: border-box;
  position: absolute;
  z-index: 1000;
  padding: 0 0.5rem 0 0.75rem;
  font-size: 0.75rem;
  font-family: 'IBM Plex Sans';
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  /* This needs to be one px larger then the other inputs. */
  height: 2.063rem;
}

.row-item-container > input[type='date'] {
  width: 7rem;
}

.row-item-container > input[type='date']::-webkit-calendar-picker-indicator {
  margin: 0;
  padding: 0;
}

.row-item-container-not-last {
  border-bottom: 1px solid var(--grey4);
}

.row-item-container:last-child .row-item-selected {
  border-bottom: 1px solid var(--build);
}

.row-item-inner-container {
  padding: 0 0.5rem 0 0.75rem;
  font-size: 0.75rem !important;
  font-family: 'IBM Plex Sans';
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row-item-error {
  border-right: 1px solid var(--red6);
  border-bottom: 1px solid var(--red6);
  border-left: 1px solid var(--red6);
  border-top: 1px solid var(--red6);
  box-sizing: border-box;
  position: relative;
}

.column-filler-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.loading-container {
  padding: 0.5rem;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--grey4);
  border-top: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.filler-item {
  height: 2rem;
  border-bottom: 1px solid var(--grey4);
}

.filler-item-column {
  background-color: var(--white);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-action-container {
  display: flex;
  align-items: center;
}

.action-button {
  margin-right: 0.5rem;
}

.icon {
  margin-right: 0.5rem;
}

.icon-margin-left {
  margin-left: 0.5rem;
}

.badge {
  height: 1.5rem;
  padding: 0 1rem;
  background-color: var(--build);
  font-size: 0.75rem;
  border-radius: 0 0 0 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: var(--white);
  font-family: 'IBM Plex Sans';
  right: 0;
  top: 0;
}

.cell-error {
  height: 0.875rem;
  width: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 0.313rem;
  color: var(--white);
  background-color: var(--red6);
  position: absolute;
  font-size: 0.375rem;
  right: 0;
  top: 0;
}

.modal-label-item {
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--grey4);
}

.divider {
  margin-top: 2.5rem;
}

.button-row {
  height: 2rem;
}

.edit-warning {
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.warning-icon {
  display: flex;
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.modal-label-item {
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--grey4);
}

.divider {
  margin-top: 2.5rem;
}

.button-row {
  height: 2rem;
}

.edit-warning {
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.warning-icon {
  display: flex;
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.export-icon {
  color: var(--grey5);
  cursor: pointer;
  font-size: 16px;
}

.export-item {
  width: 100%;
  padding: 0.5rem 1rem;
}

.export-item.disabled {
  color: var(--grey5);
}

.export-item.disabled:hover {
  cursor: default;
  background-color: inherit;
}

.export-item:hover {
  cursor: pointer;
  background-color: var(--yellow1);
}

.outer-column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-tag-container {
  height: 2rem;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  border-bottom: 1px solid var(--grey4);
  width: 100%;
}
