.information-container {
  color: var(--grey5);
  margin-top: 3rem;
  width: 18rem;
}

.information-header h4 {
  color: var(--grey5);
}

.information-container > a {
  color: var(--grey5);
  margin-top: 0.5rem;
  display: inline-block;
}

.information-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.information-header :first-child {
  margin-right: 0.5rem;
}

.information-button-container {
  margin-top: 1rem;
}

.information-button {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  color: var(--grey1);
}

.information-button > p {
  color: var(--grey1);
}

.api-container {
  margin-top: 1rem;
}

.api-header {
  border-bottom: 1px solid var(--grey3);
}

.add-api-section {
  margin-top: 1rem;
}

.api-section-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.api-section-upper-container {
  display: flex;
  gap: 0.5rem;
}

.slug-prefix {
  border: 1px solid var(--grey3);
  border-right: none;
  height: fit-content;
  min-width: fit-content;
  padding: 0.5rem;
  background: var(--grey2);
  border-radius: 5px 0 0 5px;
  color: var(--grey6);
}

.slug-text-field {
  border-radius: 0 5px 5px 0 !important;
}

.api-action-buttons {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
}
