.container {
  height: 2rem !important;
  padding: 0 1rem;
  border: 1px solid var(--grey4) !important;
  border-radius: 5px !important;
  background-color: var(--white) !important;
  color: var(--grey8) !important;
}

.container:hover {
  color: var(--primary-color-hover) !important;
}

.filled-container {
  background-color: var(--primary-color) !important;
  border: none !important;
  color: var(--grey1) !important;
}

.transparent-container {
  background-color: transparent !important;
}

.filled-container:hover {
  background-color: var(--primary-color-hover) !important;
  color: var(--grey1) !important;
}

.bordered-container {
  background-color: transparent !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-text-color) !important;
}

.bordered-container:hover {
  border: 1px solid var(--primary-color-hover) !important;
  color: var(--primary-color-hover) !important;
}

.spin {
  color: inherit !important;
}

.loading-container {
  display: flex;
}

.loading-container-text-loading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button-disabled {
  cursor: default !important;
  color: var(--grey1) !important;
  background-color: var(--grey4) !important;
}

.button-disabled:hover {
  color: var(--grey1) !important;
  background-color: var(--grey4) !important;
}

.borderless {
  border: none !important;
  box-shadow: none !important;
}

.borderless:hover {
  background-color: var(--primary1) !important;
}

.active {
  background-color: var(--primary2) !important;
}

.active:hover {
  background-color: var(--primary2) !important;
}

.transparent-disabled {
  background-color: transparent !important;
  cursor: default !important;
}

.transparent-disabled:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.large-button {
  height: 2.5rem !important;
}
