.dropzone {
  border: 3px dashed var(--grey4);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-direction: column;
  background-color: var(--grey1);
}

.l {
  height: 16rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.m {
  height: 12rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.file-icon {
  height: 1.5rem;
}

.dropzone-title {
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
}

.dropzone-icon {
  margin-right: 0.75rem;
}

.dropzone-uploaded-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}

.dropzone-dropped-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.dropdown-remove-file-container {
  font-size: 1rem;
  cursor: pointer;
  color: var(--grey5);
}

.dropdown-remove-file-container:hover {
  color: var(--warning-hover);
}

.information-container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
