.top-container {
  display: flex;
  gap: 0.75rem;
}

.base-url-container {
  display: flex;
  gap: 0.25rem;
  width: 100%;
}

.edit-container {
  padding: 0.75rem;
  border: 1px solid var(--grey3);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--white);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-container:hover {
  background-color: var(--grey2);
}

.generate-key-container {
  margin-top: 0.75rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}

.generate-key-container:hover {
  color: var(--primary-color-hover);
}

.documentation-button {
  display: flex;
  align-items: center;
  color: var(--white);
  gap: 0.5rem;
}

/* conf styling */
.api-key-container {
  display: flex;
}

.action-dropdown-icon {
  color: var(--grey5);
  cursor: pointer;
  font-size: 16px;
}

.edit-endpoint-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 1.5rem;
}

.expanded-row {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--grey3);
  margin: 0 !important;
}

.expanded-row:last-child {
  border-bottom: none !important;
}

.expanded-row:hover {
  background-color: var(--grey1) !important;
}

.expand-icon {
  color: var(--grey10);
  cursor: pointer;
}

.method-tag {
  background: var(--green3);
  border: 1px solid var(--green5);
  border-radius: 2px;
  color: var(--green7);
  padding: 0 2px;
}

.eye-icon {
  color: var(--grey5);
  cursor: pointer;
  font-size: 10px;
}
