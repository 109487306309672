.mapping-header {
  margin-top: 3rem;
}

.arrow {
  color: var(--grey4) !important;
}

.form-padding {
  padding-top: 1.5rem !important;
}

.add-mapping-button {
  margin-top: 1rem;
}
