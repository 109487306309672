.widget-metric-formatting-container {
  display: flex;
  flex-direction: column;
  background-color: var(--grey1);
  padding: 0.75rem;
  gap: 0.75rem;
}

.format-color-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.format-color-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.condition-list-limiter {
  border-bottom: 1px solid var(--grey3);
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.condition-label-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
