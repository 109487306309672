.row {
  height: 4.5rem;
}

.aggregation-wrapper {
  display: flex;
  flex-direction: column;
}

.variables-container {
  width: 100%;
  background-color: var(--white);
  margin-bottom: 1.5rem;
}

.variables-content {
  width: 100%;
  display: flex;
  min-height: 4.5rem;
  padding: 0.75rem 1.5rem;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.variables-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.variables-item-icon {
  color: var(--primary-color);
}

.variables-item-select {
  min-width: 10rem;
}

.actions-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--grey8);
}

.button > p {
  color: var(--grey8);
}

.button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  color: var(--grey6);
}

.icon.disabled {
  color: var(--white);
}

.form-container {
  width: 100%;
  background-color: var(--grey2);
  border-bottom: 1px solid var(--grey4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  padding-bottom: 0rem;
}

.form-container-inputs {
  display: flex;
  gap: 1.5rem;
  margin-left: 2rem;
}

.form-container-inputs-item {
  width: 13.5rem !important;
}

.submit-button {
  margin-bottom: 0.75rem;
}

.button.disabled p {
  color: var(--white);
}
