.ant-tree {
  border-radius: 5px !important;
}

.ant-tree-switcher-noop {
  width: 9vw !important;
}

.ant-tree .ant-tree-treenode {
  padding: 1rem 2rem !important;
  border-bottom: 1px solid var(--grey3) !important;
}

.ant-tree .ant-tree-treenode:last-child {
  border-bottom: none !important;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: var(--primary-color) !important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  border: 2px solid rgba(187, 247, 208, 0.75) !important;
  box-shadow: 0px 0.5px 1px rgb(0 0 0 / 10%) !important;
  height: 18px !important;
  width: 18px !important;
}

.ant-tree-checkbox-checked::after {
  border-color: var(--primary-color) !important;
  border-radius: 5px !important;
}

.ant-tree-checkbox-inner {
  border-radius: 5px !important;
  border-color: var(--grey4) !important;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: var(--primary-color) !important;
}
