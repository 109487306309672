.select-system-card-container {
  background-color: var(--white) !important;
  border: 1px solid var(--grey3);
  border-radius: 5px;
  width: 15rem;
  height: 14rem;
  margin-right: 1rem;
  margin-top: 1rem !important;
  align-items: center;
  justify-content: center;
}

.select-system-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-system-icon {
  height: 1.5rem;
}

.select-system-text {
  margin-top: 1.25rem;
  margin-bottom: 1.5rem !important;
  font-weight: 600;
}
