.ant-input-number {
  font-size: 14px !important;
  border-radius: 5px !important;
  border: 1px solid var(--grey4) !important;
}

.ant-input-number:hover {
  border-color: var(--primary-color) !important;
}

.ant-input-number-focused {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

/* Always show controllers */
.ant-input-number-handler-wrap {
  opacity: 1 !important;
}

.ant-input-number-disabled {
  background-color: var(--grey1) !important;
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: default !important;
}

.ant-input-number-handler-wrap {
  border-radius: 0 5px 5px 0 !important;
}

.ant-input-number-handler-up {
  border-top-right-radius: 5px !important;
}

.ant-input-number-handler-down {
  border-bottom-right-radius: 5px !important;
}

.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: default !important;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner {
  color: var(--primary-color-hover) !important;
}

.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--primary-color-hover) !important;
}
