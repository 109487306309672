.container {
  display: flex;
  align-items: center;
}

.label-container {
  position: relative;
}

.label-container > label {
  position: absolute;
  top: -9px;
  left: 15px;
  background: var(--white);
  font-size: 10px !important;
  color: var(--grey5) !important;
  z-index: 1000;
  padding: 0 2px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
}

.disabled-label {
  background: linear-gradient(
    0deg,
    rgba(241, 245, 249, 1) 41%,
    rgba(255, 255, 255, 1) 41%
  ) !important;
}

.colored-label {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 46%,
    rgba(248, 250, 252, 1) 46%
  ) !important;
}
