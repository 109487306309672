.react-resizable-handle {
  background-image: none !important;
  padding: 0;
  width: 32px !important;
  height: 32px !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s,
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne,
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw,
.react-grid-item > .react-resizable-handle.react-resizable-handle-se,
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  transform: none;
  margin: 0;
  cursor: nwse-resize !important;
  z-index: 1000 !important;
  position: absolute;
  right: 0;
  bottom: 0;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  opacity: 0;
}

.react-draggable {
  z-index: 1;
}

.react-grid-placeholder {
  background-color: #22c55e !important;
  opacity: 0.5 !important;
  pointer-events: none;
  z-index: 0 !important;
}
