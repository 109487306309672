.node-padding {
  padding: 0rem 0.5rem;
}

.node-container {
  display: flex;
  flex-direction: column;
  min-width: 8rem;
  background: var(--white);
  border: 1px solid var(--primary-color);
}

.selected {
  border: 1px solid var(--primary-color-hover) !important;
}

.container-multiple-inputs {
  border-radius: 5px 0px 0px 5px;
}

.title {
  border-bottom: 1px solid var(--grey3);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  justify-content: space-between;
}

.icon-title {
  display: flex;
  justify-content: center;
}

.title-icon {
  color: var(--primary-color);
  margin-right: 0.25rem;
}

.close-icon {
  font-size: 10px;
  color: var(--grey5);
  cursor: pointer;
}

.content {
  min-height: 3rem;
  width: 100%;
  padding: 1rem 0rem;
}

.handle-left {
  border-radius: 5px 0px 0px 5px !important;
  display: flex !important;
  left: -24px !important;
}

.handle-right {
  border-radius: 0px 5px 5px 0px !important;
  display: flex !important;
  right: -24px !important;
}

.multiple-handles-top {
  transform: translateY(-28px) !important;
}

.multiple-handles-bottom {
  transform: translateY(4px) !important;
}

.lock {
  color: var(--grey5);
  font-size: 10px;
}

.pointer-events-active {
  pointer-events: all !important;
}

.divider {
  border-top: 1px solid var(--grey3);
}

.remove-icon {
  color: var(--primary-color) !important;
  font-size: 12px;
  display: flex;
  margin-left: 0.4rem;
  cursor: pointer;
}

.remove-icon:hover {
  color: var(--primary-color-hover) !important;
}

.arrow {
  color: var(--grey3) !important;
  font-size: 8px;
  display: flex;
  margin-left: 0.25rem;
}

.add-map-row {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  color: var(--primary-text-color) !important;
}

.add-map-row:hover {
  color: var(--primary-color-hover) !important;
}

.add-icon {
  font-size: 12px;
  margin-right: 0.25rem;
}

.primary {
  color: var(--primary-text-color);
  cursor: pointer;
}

.primary:hover {
  color: var(--primary-color-hover);
}

.hidden-handles {
  border-radius: 5px;
}

.hidden-handle-right {
  background: transparent !important;
  border: none !important;
  width: 1px !important;
  right: 1px !important;
}

.hidden-handle-left {
  background: transparent !important;
  border: none !important;
  width: 1px !important;
  left: 1px !important;
}

.hidden-handle {
  background: transparent !important;
  border: none !important;
}

/* INPUT NODE STYLING */
.input-margin-top {
  margin-top: 1rem;
  color: var(--grey3) !important;
}

.input-dropdown {
  height: 100%;
}

/* LEFT JOIN NODE STYLING */
.left-join-container {
  width: 11rem;
}

.aggregate-container {
  width: 20rem;
}

.left-join-select {
  display: flex;
  align-items: center;
}

.left-join-select-lower {
  margin-top: 0.25rem;
}

/* OUTPUT NODE STYLING */

.output-container {
  width: 27rem;
}

.output-node {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.output-input {
  margin-top: 0.75rem !important;
}

.output-icon-select {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.output-icon {
  color: var(--primary-color) !important;
  margin-right: 0.25rem;
}

.output-mappings {
  border-top: 1px solid var(--grey3);
  margin-top: 1rem !important;
  padding-top: 1rem !important;
}

.output-text-wrapper {
  border: 1px solid var(--grey3);
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  background: var(--grey1);
  display: flex;
  min-height: 1.25rem; /* + padding = 1.75rem min height */
  align-items: center;
  gap: 0.5rem;
}

.output-managed-in-hp-container {
  justify-content: space-between;
}

.output-navigate-text {
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
  cursor: pointer;
  font-size: 8px;
  gap: 0.25rem;
}

.output-navigate-text:hover {
  color: var(--primary-color);
}

.output-row-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  width: 100%;
}

/* CLEAR NODE STYLING */
.clear-container {
  margin-bottom: 1rem !important;
}

.clear-attribute {
  display: flex;
  margin-top: 0.25rem !important;
}

.clear-attribut-title {
  width: 8rem;
  border: 1px solid var(--grey3);
  background: var(--grey1);
  font-size: 10px !important;
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* UNITE NODE STYLING */
.unite-wrapper {
  width: 14rem;
}

.unite-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.unite-attribute {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.unite-attribute-select {
  margin-left: 0.25rem !important;
  width: 6rem !important;
}

.unite-input {
  display: flex;
  align-items: center;
}

/* Concatenate NODE STYLING */
.concatenate-wrapper {
  width: 14rem;
}

.concatenate-container {
  display: flex;
  align-items: center;
}

.concatenate-attribute {
  display: flex;
  margin-bottom: 0.25rem;
  gap: 0.25rem;
}

.concatenate-attribute-select {
  overflow: hidden;
}

.concatenate-input {
  display: flex;
  align-items: center;
}

.concatenate-attribute-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

/* FILTER NODE STYLING */
.filter-wrapper {
  width: 16rem;
}
.filter-toggle {
  width: 100%;
  margin-top: 1rem;
}

.filter-toggle-divier-margin {
  margin-bottom: 1rem !important;
}

.filter-margin {
  margin-bottom: 0.25rem !important;
}

.filter-text-attribute {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.1rem;
}

.filter-attr-container {
  margin-bottom: 1rem;
}

.filter-remove-row {
  display: flex !important;
  align-items: center;
  margin-left: -0.2rem !important;
}

.filter-date-picker {
  height: 1.5rem !important;
  width: 100%;
}

/* ONTOLOGY NODE STYLING */
.redirect-wrapper {
  padding: 0.5rem 0 0 0 !important;
}

.ontology-outer-container {
  border: 1px solid var(--grey4);
  border-radius: 12px;
}

.ontology-redirect-wrapper {
  padding: 0 !important;
}

.ontology-redirect {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  cursor: pointer;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.ontology-redirect:hover {
  background: var(--grey1);
}

.ontology-container {
  min-width: 12rem;
}

.ontology-title {
  background: var(--primary-color);
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  padding: 0.25rem 0.75rem;
}

.ontology-title-selected {
  background: var(--primary-color-hover);
}

.ontology-title.link-table-title {
  background: var(--white);
  border-bottom: 1px solid var(--grey3);
}

.ontology-schema {
  padding: 0.75rem 0.5rem;
}

.ontology-primary-key {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.ontology-primary-key-icon {
  height: 0.75rem;
  width: 0.75rem;
  color: var(--primary-color);
}

.ontology-progress-container {
  width: 3.5rem;
  grid-gap: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ontology-text-wrapper {
  border: 1px solid var(--grey3);
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  background: var(--grey1);
  display: flex;
  min-height: 1.25rem; /* + padding = 1.75rem min height */
  justify-content: space-between;
  align-items: center;
}

.marked-relation-attribute {
  border-color: var(--green6);
  background-color: var(--green2);
}

.static-input-text {
  line-height: 0.75rem;
  padding-right: 0.25rem;
}

.ontology-column-wrapper {
  padding: 0.75rem 0.5rem;
}

.ontology-column {
  margin-bottom: 0.25rem;
}

.ontology-arrow-icon {
  color: var(--primary-color);
  font-size: 8px;
  margin-left: 0.25rem;
}

.relation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  position: absolute;
}

.redirect-disabled {
  background: var(--grey2);
  cursor: not-allowed !important;
}

.redirect-disabled:hover {
  background: var(--grey2);
}

.node-number {
  border-radius: 50%;
  background: var(--green3);
  color: var(--green6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.75rem;
  width: 0.75rem;
  font-size: 8px;
}

.node-number-select-align {
  margin-top: 3px;
}

.node-number-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.in-node-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 1rem);
  margin-top: 1px;
}

/* DateParserNode styling */
.date-parser-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 15rem;
}

.date-parser-input-fields {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
}

.date-parser-input-arrow {
  font-size: 8px;
  color: var(--primary-text-color);
}

.date-parser-format-container {
  border-bottom: 1px solid var(--grey3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.date-parser-format-icon {
  font-size: 8px;
  color: var(--grey5);
  cursor: pointer;
}

.date-parser-format-information {
  background-color: var(--grey1);
  padding: 0.5rem 0.25rem;
}

.date-parser-format-header {
  color: var(--grey5);
  font-size: 10px !important;
  font-weight: 700 !important;
  background-color: var(--grey3);
  width: 100%;
  padding: 0 0.25rem;
}

.date-parser-format-option-container {
  padding-left: 0.25rem;
  height: 14px !important;
  align-content: center;
}

.attribute-label {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.tooltip-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: var(--grey5);
  font-size: 10px;
  margin-top: 0.25rem;
}

/* TimeDifferenceNode styling */
.time-difference-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 15rem;
}

.time-difference-date-section {
  width: 100%;
  padding: 0.75rem 0.25rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  background: var(--grey1);
}

.time-difference-date-sub-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.node-toggle {
  font-size: 10px !important;
}

.node-toggle :global(.ant-segmented-item-label) {
  font-size: 10px !important;
}

/* AggregateNode styling */
.aggregate-attribute-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

/* ReplaceNode styling */
.replace-container {
  width: 17rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.replace-conditions {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--grey3);
  gap: 0.5rem;
  padding-top: 0.5rem;
}

/* CalculateNode styling */
.calculate-node-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 18rem;
}
