.ant-steps-item-content {
  background: var(--white);
  width: 17.5rem !important;
  border-radius: 5px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}

.ant-steps-item-title {
  line-height: normal !important;
}

.ant-steps-item-description {
  font-size: 14px !important;
  color: var(--grey10) !important;
  padding-bottom: 0 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background: var(--grey3) !important;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: transparent !important;
  border: 2px solid var(--primary-color);
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: transparent !important;
  border: 2px solid var(--primary-color);
}

.ant-steps-item-icon {
  width: 0.75rem !important;
  height: 0.75rem !important;
  left: 1px;
}

.ant-steps-item-tail::after {
  margin-left: 14px !important;
}
