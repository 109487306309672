.container {
  width: 100%;
}

.page-header {
  margin-bottom: 0.5rem;
}

.button-container {
  background-color: var(--collect);
}

.search-container {
  width: 100%;
  background: var(--white);
  border-radius: 5px;
}

.search-header {
  padding: 1rem;
  border-bottom: 1px solid var(--grey4);
}

.search-footer {
  padding: 1rem;
  border-top: 1px solid var(--grey4);
}

.search-input {
  padding: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid var(--grey4);
}

.search-data {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 25rem;
  overflow-y: scroll;
}

.content {
  width: 100%;
  margin-top: 2rem;
}

.information-container {
  color: var(--grey5);
  margin-top: 3rem;
}

.information-container h4,
.information-container p {
  color: var(--grey5);
}

.information-container > a {
  color: var(--grey5);
  margin-top: 0.5rem;
  display: inline-block;
}

.information-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.information-header :first-child {
  margin-right: 0.5rem;
}

.edit-buttons {
  display: flex;
  flex-direction: row;
}

.save-settings {
  margin-left: 0.5rem;
}

.endpoint-row-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.remove-icon-container {
  border: 1px var(--grey4) solid;
  cursor: pointer;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: var(--grey5);
  background-color: var(--white);
  font-size: 18px;
}
