.get-started-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
}

.get-started-image {
  width: 10rem;
  height: 10rem;
  margin: 0 !important;
}

.get-started-image :global(.ant-empty-image) {
  height: 100% !important;
  width: 100% !important;
  margin-bottom: 0 !important;
}

.get-started-image :global(.ant-empty-image) svg {
  height: 100% !important;
  width: 100% !important;
}
