.container {
  margin-top: 2rem;
}

.divider {
  width: 100%;
  border-bottom: 1px solid var(--grey3);
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.list-progress-container {
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0.25rem;
  padding: 2px;
}

.icon {
  margin-right: 0.75rem;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.example-container {
  padding: 0.5rem 1rem;
}

.example-text {
  margin-bottom: 0.5rem !important;
}

.example-body {
  display: inline-flex;
  padding: 0rem 0.75rem;
  height: 3rem;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 5px;
  width: 100%;
}

.ellipsis-icon {
  color: var(--grey5);
  font-size: 20px;
  cursor: pointer;
}

.attribute-name-container {
  max-width: 20vw;
}
