.attribute-label {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.form-item {
  margin-bottom: 0 !important;
}

.form {
  margin-bottom: 2rem;
}