.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  width: fit-content;
  height: 100%;
}

.container small {
  padding-top: 1px;
  margin-left: 0.25rem;
}

.cube {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.188rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary4);
}

.hidden-text-path {
  margin-left: 0.25rem;
}
