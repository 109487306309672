.ant-select-selector {
  border-radius: 5px !important;
  box-shadow: none !important;
  border-color: var(--grey4) !important;
}

.ant-select-selector:hover {
  border-color: var(--primary-color) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--grey3) !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: var(--grey2) !important;
  border-color: var(--grey4) !important;
  cursor: default !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: default !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 46px !important;
}

.ant-select {
  font-size: 14px !important;
}

.ant-select-arrow {
  border-left: 1px solid var(--grey3) !important;
  top: 25% !important;
  height: 80% !important;
  padding-left: 0.75rem !important;
}

.ant-select-sm > .ant-select-arrow {
  top: 35% !important;
  border-left: none !important;
  padding-left: 0 !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 2.5rem !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 1.5rem !important;
  border-radius: 2px !important;
  align-items: center !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
  .ant-select-selection-item {
  height: 1.5rem !important;
  font-size: 10px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--green1) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--green6) !important;
}

.select-grid > div > div > .ant-select-dropdown {
  width: 240px !important;
}

.select-grid
  > div
  > div
  > .ant-select-dropdown
  > div
  > .rc-virtual-list
  > .rc-virtual-list-holder {
  height: 256px !important;
}

.select-grid
  > div
  > div
  > .ant-select-dropdown
  > div
  > .rc-virtual-list
  > .rc-virtual-list-scrollbar {
  display: none !important;
}

.select-grid
  > div
  > div
  > .ant-select-dropdown
  > div
  > .rc-virtual-list
  > .rc-virtual-list-holder
  > div
  > .rc-virtual-list-holder-inner {
  display: grid !important;
  grid-template-columns: 25% 25% 25% 25% !important;
  text-align: center !important;
}

.ant-select-selection-placeholder {
  color: var(--grey5) !important;
}
