.pagination-wrapper {
  position: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.pagination-wrapper small {
  color: var(--grey5);
}

.pagination-right-wrapper {
  display: flex;
}

.pagination-right-wrapper button:first-child {
  margin-right: 0.5rem;
}

.icon {
  color: var(--grey6);
}

.icon-left {
  margin-right: 0.5rem;
}

.icon-right {
  margin-left: 0.5rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button > * {
  color: inherit !important;
}

.button.left {
  float: left;
}

.button.right {
  float: right;
}
