.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--primary-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-color) !important;
}

.ant-picker-today-btn {
  color: var(--primary-text-color) !important;
}

.ant-picker-today-btn:hover {
  color: var(--primary-color) !important;
}

.ant-picker {
  width: 100%;
}

.ant-picker-range .ant-picker-active-bar {
  background: var(--green6) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--green2) !important;
}

.ant-picker-cell.ant.picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
  background: var(--green2) !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover::after {
  border-color: var(--green6) !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after {
  border-color: var(--green6) !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
  border-color: var(--green6) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after {
  border-color: var(--green6) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: var(--green2) !important;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  background-color: var(--green3) !important;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background-color: var(--green3) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after {
  border-color: var(--green6) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: var(--green5) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--green5) !important;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: var(--primary-text-color) !important;
  background: var(--green2) !important;
  border-color: var(--primary-color) !important;
}
