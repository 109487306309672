.ant-cascader-input.ant-input {
  height: 3rem !important;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  font-weight: 400 !important;
  background-color: var(--primary1) !important;
}

.ant-cascader-menu-item {
  display: flex !important;
}

.ant-cascader-menu:first-child {
  border-color: var(--grey4) !important;
}

.ant-cascader-menu {
  min-width: 10rem !important;
}

.ant-cascader-picker {
  border-radius: 5px !important;
}

.ant-cascader-menu:last-child {
  border-right: none !important;
}

.ant-cascader-picker-disabled {
  cursor: default !important;
}

.ant-cascader-picker-disabled {
  background: var(--grey2) !important;
}

/* Production is running this class instead of cascader :S */
.ant-select-selection-placeholder {
  align-items: center;
  display: flex;
}

.ant-input.ant-cascader-input.ant-input-sm {
  height: 1.5rem !important;
  border-radius: 2px !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: var(--green5) !important;
  border-color: var(--green5) !important;
}

.ant-cascader-checkbox-inner::after {
  background-color: var(--green5) !important;
}

.ant-cascader-menu-item:hover {
  background: var(--green1) !important;
}

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: var(--primary-color) !important;
}
