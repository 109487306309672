.react-flow__controls-button {
  border-bottom: none;
  margin-top: 0.125rem;
  border-radius: 5px;
}

.react-flow__controls-button:hover {
  background: var(--grey3);
}

.react-flow__edge {
  pointer-events: all;
  cursor: pointer;
}
