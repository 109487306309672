.container {
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-buttons {
  display: flex;
  grid-gap: 0.5rem;
}

.icon {
  margin-right: 0.5rem;
}

.modal-container {
  background: var(--white);
  padding-bottom: 0rem !important;
  border-radius: 0.313rem;
}

.modal-left-container {
  border-right: 1px solid var(--grey4);
  padding: 1.5rem;
  padding-top: 2.5rem;
  width: 12rem;
}

.modal-left-container * {
  margin-bottom: 1.5rem !important;
}

.modal-right-container {
  margin-top: 4rem;
  width: 100%;
}

.modal-footer-container {
  width: 100%;
  float: right;
  border-top: 1px solid var(--grey4);
}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  height: 5rem;
  align-items: center;
  margin-right: 2rem;
}

.link {
  color: var(--primary-text-color);
  cursor: pointer;
}

.relation-modal-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  height: 4.5rem;
  margin: 1.5rem;
}

.relation-modal-container :global(.ant-select-selector) {
  height: 2rem !important;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
}

.relation-modal-content {
  padding: 0;
}

.relation-modal-footer-container {
  margin: 0;
}

.edit-container {
  border: 2px solid var(--primary-color);
}

.header-container-left {
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
  color: var(--grey4);
}

.option-card {
  border: 1px solid var(--grey3);
  border-radius: 5px;
  height: 8rem;
  width: 12rem;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.option-card:hover {
  border: 1px solid var(--primary-color);
  background: var(--grey1);
  cursor: pointer;
}

.option-card-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.colored-icon {
  margin-right: 0.5rem;
  font-size: 14px;
  color: var(--primary-color);
}

.add-option-cards {
  display: flex;
  flex-wrap: wrap;
}
