.ant-menu {
  background-color: transparent !important;
  border-right: none !important;
}

.ant-menu-item-group-list .ant-menu-item {
  padding: 0 1rem !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ant-dropdown {
  border-radius: 0.313rem;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  min-width: 12rem !important;
}

.ant-menu-item-divider {
  background-color: var(--grey4) !important;
}

.ant-dropdown-menu {
  box-shadow: none !important;
}
