.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: white;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.upper-header {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}
