.ant-alert {
  display: flex !important;
  
  width: 100%;
}

.ant-alert-icon {
  align-self: baseline;
  margin: 0.25rem 0.5rem 0 0;
}

.ant-alert-banner {
  width: 100vw !important;
}

.ant-alert-content {
  flex: inherit !important;
}

.ant-alert-info {
  background-color: var(--blue2) !important;
  border-color: var(--blue5) !important;
}

.ant-alert-info .ant-alert-icon {
  color: var(--blue6) !important;
}

.ant-alert-success {
  background-color: var(--green2) !important;
  border-color: var(--green5) !important;
}

.ant-alert-success .ant-alert-icon {
  color: var(--green6) !important;
}

.ant-alert-warning {
  background-color: var(--yellow2) !important;
  border-color: var(--yellow5) !important;
}

.ant-alert-warning .ant-alert-icon {
  color: var(--yellow6) !important;
}

.ant-alert-error {
  background-color: var(--red2) !important;
  border-color: var(--red5) !important;
}

.ant-alert-error .ant-alert-icon {
  color: var(--red6) !important;
}
