.container {
  padding: 1.5rem 2rem 1.5rem 2rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.background {
  background: var(--grey2) !important;
}

.active {
  border: 2px solid var(--secondary-color);
  padding: 25px 14px 25px 14px;
}

.header {
  margin-bottom: 2rem;
}

.children {
  display: flex;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
