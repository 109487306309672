.success {
  color: var(--primary-text-color);
}

.info {
  color: var(--blue6);
}

.warning {
  color: var(--yellow6);
}

.error {
  color: var(--red6);
}

.container {
  display: flex;
  gap: 0.5rem;
}

.title {
  white-space: nowrap;
}
