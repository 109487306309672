.ant-input {
  border-radius: 5px !important;
  border-color: var(--grey3) !important;
  height: 2rem;
}

.ant-input-password-small {
  border-radius: 5px !important;
  border-color: var(--grey3) !important;
}

.ant-input:hover {
  border-color: var(--primary-color) !important;
}

.ant-input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.ant-input-focused {
  border-color: var(--primary-color) !important;
}

.ant-input-disabled {
  background: var(--grey2) !important;
  cursor: default !important;
}

::placeholder {
  color: var(--grey5) !important;
}

.ant-input-disabled:hover {
  border-color: var(--grey3) !important;
}

.ant-input-affix-wrapper-focused {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.ant-picker-focused {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.ant-picker:hover {
  border-color: var(--primary-color) !important;
}

.ant-picker {
  border-radius: 5px !important;
  border-color: var(--grey3) !important;
}

.ant-btn-primary {
  background-color: var(--primary-color) !important;
}

.ant-picker-content {
  width: 15rem !important;
}

.ant-input-affix-wrapper-lg {
  height: 2.5rem !important;
  border-radius: 5px !important;
  border-color: var(--grey3) !important;
  display: flex;
  align-items: center;
}

.ant-input-affix-wrapper-sm {
  height: 2rem !important;
  border-radius: 5px !important;
  border-color: var(--grey3) !important;
  display: flex;
  align-items: center;
}

.ant-input-affix-wrapper-lg:hover {
  border-color: var(--primary-color) !important;
}

.ant-input-affix-wrapper-sm:hover {
  border-color: var(--primary-color) !important;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 5px 0 0 5px !important;
  border-right: none;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: var(--red4);
}

.ant-input-lg {
  height: 2.5rem !important;
  font-size: 14px !important;
}

.ant-input-sm {
  height: 1.5rem !important;
  border-radius: 2px !important;
  font-size: 10px !important;
}

.ant-form-item textarea#ant-input {
  height: auto !important;
}

.ant-input-affix-wrapper-lg > .ant-input-lg {
  border-bottom: 1px solid var(--grey4) !important;
  border-top: 1px solid var(--grey4) !important;
  border-radius: 0 !important;
}

.ant-input-affix-wrapper-lg > .ant-input-lg:hover {
  border-bottom: 1px solid var(--primary-color) !important;
  border-top: 1px solid var(--primary-color) !important;
}

.ant-input-group-addon {
  padding: 0 !important;
  border: none !important;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-input-affix-wrapper {
  padding: 0 0.5rem !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary-color) !important;
}

.ant-input-affix-wrapper {
  border-radius: 5px !important;
}
