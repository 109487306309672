.file-container {
  margin-top: 2rem;
}

.file-table {
  margin-top: 1rem;
}

.add-file-options {
  align-items: center;
}

.lock-button {
  margin-left: 1rem;
}

.file-button {
  margin-top: 2rem;
}

.write-mode-btn {
  margin-top: 0.5rem;
}

.upload-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  grid-row-gap: 1.5rem;
  text-align: center;
}

.upload-container > .icon {
  width: 3rem;
  height: 3rem;
  color: var(--primary-color);
}

.locked-table {
  color: var(--grey5) !important;
}

.header-margin {
  margin-top: 2rem !important;
  margin-bottom: 0.5rem !important;
}

.modal-margins {
  margin-top: 1rem !important;
}

.icon {
  font-size: 16px;
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.pwd-reset-header {
  display: flex;
  align-items: center;
}

.sftp-container {
  margin-top: 0.5rem;
}

.sftp-input-field {
  display: flex;
  align-items: center;
  background: var(--white);
  border-radius: 5px;
  border: 1px solid var(--grey4);
  padding-left: 1rem;
  margin: 0.5rem 0rem;
}

.sftp-input-div {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid var(--grey4);
}

.hide-pw-icon {
  margin-right: 0.5rem;
  margin-left: auto;
  cursor: pointer;
  color: var(--grey5);
}

.pwd-reset-button {
  margin-top: 1rem !important;
  width: fit-content;
  margin-right: 0;
  margin-left: auto;
}

.pwd-reset-container {
  display: flex;
  flex-direction: column;
}
