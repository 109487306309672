.row-container {
  display: flex;
  justify-content: space-between;
}

.space-container {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.dot {
  border-radius: 2px;
  height: 0.5rem;
  width: 0.5rem;
}

.dot-green {
  background: var(--green5);
}

.dot-red {
  background: var(--red5);
}
