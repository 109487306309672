.container {
  border-radius: 0.75rem;
  border: 1px solid var(--grey3);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  position: relative;
  margin-bottom: 3px;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.error-component-img {
  width: 40%;
  max-height: 40%;
}

.menu {
  position: absolute;
  right: -1px;
  top: -1px;
  width: 3.5rem;
  background-color: var(--grey2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid var(--grey3);
  height: calc(100% + 2px);
  z-index: 2005;
}

.menu-top {
  display: flex;
  flex-direction: column;
}

.dark-icon {
  color: var(--grey5);
  font-size: 16px;
  cursor: pointer;
}

.disabled-icon {
  color: var(--grey4);
  cursor: default;
}

.light-icon {
  color: var(--grey3);
  font-size: 16px;
}

.grab {
  cursor: grab;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.spin {
  font-size: 2rem !important;
  color: var(--primary-color) !important;
}

.chart-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.paginate-adjustment {
  padding: 1.5rem 0;
}

.title-adjustment {
  height: calc(100% - 2rem) !important;
}

.key-figure-contaner {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--grey10);
  border-radius: 0.75rem;
  position: relative;
  gap: 0.5rem;
  font-weight: 500 !important;
}

.key-figure-value-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.25rem;

  line-height: 0.7 !important;
}

.error-text {
  margin-top: 1rem;
}

.bar-wrap {
  width: 95%;
  height: 90%;
  position: relative;
}

.paginator-container {
  position: relative;
  width: calc(100% - 5rem);
  height: 3rem;
  margin-top: 1rem;
}

.paginate-ticker {
  background: var(--grey1);
  position: absolute;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.pagination-slider {
  appearance: none;
  width: 100%;
  height: 3rem;
  background: var(--grey4);
  outline: none;
  padding: 0;
  margin: 0;
}

.pagination-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 3rem;
  border: 1px solid var(--grey6);
  width: 20%;
  z-index: 1000;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  position: relative;
}

.pagination-slider::-webkit-slider-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-slider:active::-webkit-slider-thumb {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-slider::-moz-range-thumb {
  appearance: none;
  border: none;
  height: 3rem;
  border-top: 1px solid var(--grey6);
  border-bottom: 1px solid var(--grey6);
  width: 20%;
  z-index: 1000;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  position: relative;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.pagination-slider::-moz-range-track {
  background: none;
}

.pagination-slider::-moz-range-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-slider:active::-moz-range-thumb {
  background: rgba(0, 0, 0, 0.1);
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

.pivot-table-container {
  width: 100%;
  height: calc(100% + 2rem);
  overflow: hidden;
  font-weight: 500;
  border-radius: 0.75rem;
}

.pivot-table-container::-webkit-scrollbar {
  display: none;
}

.expand-icon {
  margin-right: 0.75rem;
  font-size: 10px !important;
  cursor: pointer;
}

.table-summary {
  border-top: 1px solid var(--grey3) !important;
  font-weight: 500;
}

.pivot-table-row {
  font-weight: 100;
}

.icon-wrapper {
  background: var(--grey3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  width: 100%;
  height: 2rem;
  background: var(--grey1);
  border-radius: 0.75rem 0.75rem 0 0;
  border-bottom: 1px solid var(--grey3);
  align-items: center;
  padding-left: 2rem;
}

.table-title {
  height: 2rem;
  display: flex;
  align-items: center;
  background: var(--grey1);
  padding-left: 1rem;
  font-weight: 500;
  border-bottom: 1px solid var(--grey3);
}
