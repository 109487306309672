.footer {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  height: 5rem;
  align-items: center;
  margin-right: 2rem;
}

.form-item {
  margin-bottom: 0rem !important;
}

.form-row {
  margin-bottom: 1rem !important;
}

.lock-container {
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--grey4);
  border-left: none !important;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey2);
}
