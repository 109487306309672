.paginate-ticker {
  background: var(--grey1);
  position: absolute;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.pagination-slider {
  appearance: none;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  background: var(--grey4);
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.pagination-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 32px;
  border-radius: 8px;
  background: rgba(15, 23, 42, 0.12);
  width: 20%;
  z-index: 1000;
  cursor: grab;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  position: relative;
}

.pagination-slider::-webkit-slider-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-slider:active::-webkit-slider-thumb {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-slider::-moz-range-thumb {
  appearance: none;
  border: none;
  height: 32px;
  border-radius: 8px;
  border-top: 1px solid var(--grey6);
  border-bottom: 1px solid var(--grey6);
  width: 20%;
  z-index: 1000;
  cursor: grab;
  transition: background 0.15s ease-in-out;
  position: relative;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.pagination-slider::-moz-range-track {
  background: none;
}

.pagination-slider::-moz-range-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}
.pagination-slider:active::-moz-range-thumb {
  background: rgba(0, 0, 0, 0.1);
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
