.col {
  background: var(--white);
  height: fit-content;
  border-radius: 5px;
}

.info-title {
  margin-bottom: 2.5rem !important;
}

.description {
  margin-bottom: 1rem !important;
}

.first-col-section {
  width: 100%;
  border-bottom: 1px solid var(--grey3);
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.second-col-section {
  width: 100%;
  border-bottom: 1px solid var(--grey3);
  padding: 1rem 1.5rem;
  padding-bottom: 0;
}

.third-col-section {
  width: 100%;
  padding: 1rem 1.5rem;
}

.col-form-item {
  margin-bottom: 0 !important;
}

.button-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26rem;
}

.api-container {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid var(--grey3);
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
}

.slug-container {
  display: flex;
}

.slug-prefix {
  border: 1px solid var(--grey3);
  border-right: none;
  height: fit-content;
  min-width: 24.5rem;
  padding: 0.5rem;
  background: var(--grey2);
  border-radius: 5px 0 0 5px;
  color: var(--grey6);
}

.slug-input-field {
  border-radius: 0 5px 5px 0 !important;
}
