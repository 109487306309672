.sub-header-children {
  color: var(--grey10) !important;
}

.button-margin {
  margin-right: 0.5rem;
}

.section-header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-header-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.section {
  width: 100%;
  margin-top: 2rem;
}

.small-tag {
  color: var(--grey5);
}

.alert-attribute-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.alert-attribute-list-item {
  color: var(--primary-text-color);
}

.alert-attribute-list-item::before {
  content: '• ';
  color: var(--grey10);
  margin: 0 0.5rem;
}

.table-preview-list {
  margin-top: 1.5rem;
}

.link-tables-container {
  width: 100%;
  margin-top: 3rem;
}

.link-tables-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.link-tables-body {
  border: 1px solid var(--grey4);
  border-radius: 5px;
  padding: 2.5rem 1.5rem 2.5rem 4.5rem;
  position: relative;
}

.link-tables-body.edit {
  border: 1px solid var(--shape);
}

.link-tables-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link-tables-button p {
  color: var(--grey8);
}

.link-tables-button-icon {
  margin-right: 0.5rem;
  color: var(--grey6);
}

.icon {
  margin-right: 0.5rem;
}

.link-tables-edit-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.badge {
  height: 1.5rem;
  padding: 0 1rem;
  background-color: var(--shape);
  font-size: 0.75rem;
  border-radius: 0 0 0 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: var(--grey1);
  font-family: 'IBM Plex Sans';
  right: 0;
  top: 0;
}

.reference-container {
  display: inline-flex;
  flex-direction: column;
}

.add-attribute-container {
  margin-left: 2rem;
}

.drawing-board-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataset-title {
  margin-bottom: 0.5rem !important;
}

.layout-container {
  display: flex;
  grid-gap: 0.75rem;
  justify-content: flex-end;
}

.drawing-board-container {
  height: 60vh;
}
