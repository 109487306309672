.input-wrapper-container {
  width: 100%;
}

.input-wrapper-container:hover {
  border-color: var(--primary) !important;
}

.search-button-container {
  height: 2.5rem;
  width: 2.5rem;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  color: var(--white);
}

.search-button-container:hover {
  background: var(--primary-color-hover);
}

.search-icon {
  color: var(--white);
}

.medium-sized-button {
  height: calc(2rem + 2px);
  width: 2rem;
}
