.text {
  position: relative;
  color: var(--primary-text-color) !important;
  cursor: pointer;
  width: fit-content;
}

.text:hover {
  color: var(--primary-color-hover) !important;
}

.icon-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  color: var(--primary-text-color) !important;
  width: fit-content;
}

.icon-container:hover {
  color: var(--primary-color-hover) !important;
}

.underline {
  text-decoration-line: underline;
}

.disabled {
  color: var(--grey6) !important;
  cursor: default;
}

.disabled:hover {
  color: var(--grey5) !important;
}
