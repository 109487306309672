.container {
  height: 2rem !important;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 5px !important;
}

.no-header {
  margin-top: -1rem;
}

.container:hover {
  border-color: var(--primary-color) !important;
}

.item {
  color: var(--grey10);
}

.item:hover {
  background-color: var(--grey1) !important;
  color: var(--grey10) !important;
}

.items-container {
  background-color: var(--white);
  box-shadow: 0px 4px 8px var(--shadow);
  width: 15rem !important;
}

.button {
  background-color: var(--white) !important;
  display: flex !important;
  align-items: center !important;
  height: 2rem !important;
  padding: 0 1rem !important;
}

.button-filled {
  background-color: var(--primary-color) !important;
  border: none !important;
}

.button-filled:hover {
  background-color: var(--primary-color-hover) !important;
}

.button-transparent {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.disabled-button {
  background-color: var(--grey4) !important;
  color: var(--white) !important;
  cursor: default !important;
}

.disabled-button:hover {
  border-color: var(--grey4) !important;
}

.icon {
  margin-left: 0.5rem;
  color: var(--grey6);
}

.filled {
  color: var(--grey1) !important;
}

.dropdown-header {
  margin-left: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem !important;
}

.remove {
  color: var(--red6) !important;
}

.disabled-remove {
  color: var(--red4) !important;
}

.dropdown-button-header > div {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.dropdown-button-sub-header > div {
  padding-bottom: 1rem !important;
  padding-top: 0 !important;
}

.item-icon-container {
  height: 4rem !important;
  display: flex !important;
  align-items: center !important;
  line-height: 2rem !important;
}

.item-icon-wrapper {
  display: flex;
  align-items: center;
}

.item-icon {
  margin-right: 0.5rem;
  height: 1rem;
}

.item-icon-sublabel {
  line-height: 2rem !important;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-icon-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}
