.button-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--white);
}

/* conf styling */
.tree-attribute-title {
  width: 8vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.tree-attribute-type-title {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  white-space: nowrap;
}

.header-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.header-text-container {
  display: flex;
  flex-direction: column;
}

.move-container-position {
  margin-left: 1rem;
  margin-right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  font-size: 18px;
}

.move-column-disabled {
  cursor: default;
}

.move-column {
  cursor: pointer;  
}

.move-column:hover {
  color: var(--primary-color)
}
