.container {
  height: 100%;
  width: 100%;
  background: var(--grey2);
}

.control-btns {
  box-shadow: none;
}

.custom-buttons {
  position: absolute;
  top: 1rem;
  left: 1rem;
  box-shadow: none;
  height: fit-content;
}
