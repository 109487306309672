.title {
  margin-bottom: 2.5rem !important;
}

.card-container {
  display: flex;
  gap: 1rem;
  flex-flow: wrap;
}

.selector-card {
  border-radius: 5px;
  min-width: 16rem;
  height: 8rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--white);
  border: 1px solid var(--grey4);
}

.selector-card-small {
  min-width: 11rem;
  width: 11rem;
  height: 5rem;
}

.selector-card:hover {
  border: 3px solid var(--primary-color);
}

.active-selector-card {
  border: 3px solid var(--primary-color);
}

.content-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.img {
  width: 2rem;
}

.img-small {
  width: 1rem;
}

.card-title {
  margin-left: 0.75rem;
  font-weight: 600;
}

.fa-icon {
  font-size: 20px;
  color: var(--primary-color) !important;
}
