.container-hidden {
  top: -130vh !important;
}

.container {
  background: var(--grey1);
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.5s;
  z-index: 1500;
}

.second-path {
  color: var(--primary-text-color);
  margin-left: 0.25rem;
}

.active-style {
  background: var(--primary-color);
  color: var(--grey1);
}

.inactive-style {
  color: var(--primary-text-color);
  background: var(--grey1);
  border: 1px solid var(--primary-color);
}

.bordered {
  border-color: var(--primary-color);
}

.stepper-container {
  width: fit-content;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 14rem;
  padding-bottom: 4rem;
  padding-left: 8rem;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  border-bottom: 1px solid var(--grey3);
}

.header-title {
  position: absolute;
  width: 40vw;
  margin-left: 30vw;
  margin-right: 30vw;
  display: flex;
  justify-content: center;
}

.scroll-container {
  overflow-y: scroll;
  height: 100vh;
}

.content {
  padding-left: 30.5rem;
  padding-right: 8rem;
  padding-bottom: 4rem;
  padding-top: 26vh;
  overflow-y: scroll;
  display: flex;
  align-content: center;
  min-height: 100%;
  width: 100%;
}

.content-center {
  padding-bottom: 4rem;
  padding-top: 6rem;
  overflow-y: scroll;
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  padding-left: 22rem;
  padding-right: 22rem;
}

.close-icon {
  font-size: 1rem;
  color: var(--grey5);
  cursor: pointer;
}

.left-button {
  margin-right: 0.25rem;
}

.icon-left {
  margin-right: 0.5rem;
}

.icon-right {
  margin-left: 0.5rem;
}

.stepper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.stepper-line {
  border: 0.5px solid var(--grey3);
  width: 1px;
  height: 2.5rem;
}

.step-text-container {
  margin-right: 1.5rem;
  width: 10rem;
  text-align: right;
}

.step-text-container-margin {
  margin-top: 2.5rem;
}

.step-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}
