.spin-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.spin {
  color: var(--primary-color) !important;
}
