.container {
  background-color: var(--white);
  height: 100%;
  display: flex;
  z-index: 1;
}

.content {
  width: 20vw;
  border-left: 1px solid var(--grey3);
  height: 100%;
}

.inner-content {
  padding: 2rem;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid var(--grey3);
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  height: calc(3.5rem - 2px);
  gap: 0.75rem;
}

.close-icon {
  color: var(--grey5);
  cursor: pointer;
}

.close-icon:hover {
  color: var(--grey4);
}

.side-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid var(--grey3);
  height: 100%;
  width: 3rem;
  padding: 1rem 0;
}

.icon {
  color: var(--grey5);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon:hover {
  color: var(--grey4);
}
