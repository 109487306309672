.container {
  border: 1px solid var(--grey3);
  border-radius: 5px;
}

.empty-tree {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
