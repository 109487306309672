.title-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.toggle-icon {
  background: var(--grey2);
  color: var(--grey5);
  height: fit-content;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  margin-left: 0.25rem;
  display: flex;
  height: fit-content;
  font-size: 18px;
}

.toggle-icon-activated {
  background: var(--primary-color);
  color: var(--white);
}

.list-progress-container {
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0.25rem;
  padding: 2px;
}

/* OntologyBoard styling */

.button-container {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.inactive-button {
  color: var(--grey3) !important;
}

.view-toggle-container {
  display: flex;
  justify-content: space-between;
}

.view-toggle-inner-container {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
}

.view-option {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.import-section-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* OntologyList styling */

.list-container {
  padding-top: 2rem;
  border-top: 1px solid var(--grey3);
}

.list-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

/* ManageRelations styling */
.manage-relations-action-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  width: 100%;
}

.manage-relations-button-container {
  display: flex;
  grid-gap: 0.5rem;
  padding: 1rem 1rem 0 1rem;
}

.manage-relations-divider {
  border-top: 1px solid var(--grey3);
  width: 100%;
}

.manage-relations-close-button {
  width: fit-content !important;
}

.manage-relations-close-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem 1rem;
}

.space {
  margin-left: 0.25rem;
}

/* RelationModal styling */
.relation-modal-container {
  display: flex;
  grid-gap: 1rem;
  height: 100%;
  margin: 2rem 1.5rem 1rem 1.5rem;
  flex-direction: column;
}

.relation-modal-selects-container {
  display: flex;
  width: 100%;
  grid-gap: 0.5rem;
}

.relation-options {
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.relation-modal-select {
  width: 100%;
  padding-top: 1rem;
}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  height: 5rem;
  align-items: center;
  margin-right: 2rem;
}

.relation-modal-content {
  padding: 0;
}

.relation-modal-footer-container {
  margin: 0;
}

.edit-title {
  display: flex;
  justify-content: space-between;
}

.relation-table {
  margin-bottom: 0.5rem !important;
}

.drawing-board-container {
  height: calc(100% - 56px);
}

.drawing-board-container-homepal-user {
  height: calc(100% - 105px);
}

.board-container {
  height: 100%;
  flex: 1 1;
}

.container {
  display: flex;
  flex-grow: row nowrap;
  height: 100%;
  width: 100%;
}

.informations-model-picker-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.spin-container {
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.spin {
  color: var(--primary-color) !important;
}
