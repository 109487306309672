.container {
  height: 100%;
}

.top-container {
  margin: 0 2rem;
}

.table-container {
  width: 100%;
  margin-top: 2rem;
}

.page-header {
  margin-bottom: 0.5rem;
}

.tag {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 2px;
  color: var(--grey5);
}

.tag small {
  color: var(--grey5);
}

.tag *:first-child {
  margin-right: 0.5rem;
}

.green {
  background-color: var(--green3);
  color: var(--green10);
}

.yellow {
  background-color: var(--yellow2);
  color: var(--yellow7);
}

.yellow small {
  color: var(--yellow7);
}

.green small {
  color: var(--green10);
}

.select-system-card-container {
  background-color: var(--grey1) !important;
  border: 1px solid var(--grey3);
  border-radius: 5px;
  width: 15rem;
  height: 14rem;
  margin-right: 1rem;
  margin-top: 1rem !important;
  align-items: center;
  justify-content: center;
}

.select-system-icon {
  height: 1.5rem;
}

.select-system-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-system-text {
  margin-top: 1.25rem;
  margin-bottom: 1.5rem !important;
  font-family: 'IBM Plex Sans';
  font-weight: 600;
}

.services {
  margin-top: 5rem;
}
