.container {
  margin-top: 2rem;
}

.divider {
  width: 100%;
  border-bottom: 1px solid var(--grey3);
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.space {
  margin-right: 0.25rem;
}

.table-container {
  margin-top: 1rem;
}

.warning-icon {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  color: var(--red5) !important;
  font-size: 12px;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0;
}

.valid-icon {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  color: var(--green5) !important;
  font-size: 12px;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0;
}

.download-icon {
  font-size: 16px;
  color: var(--grey5);
  cursor: pointer;
}

.active-attribute {
  background: var(--red3);
  padding: 0.25rem 0.5rem !important;
  border-radius: 5px;
}

.row-error {
  color: var(--red5);
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
}

.row-error-marked {
  background: var(--red3);
  padding: 0.25rem 0.5rem !important;
  border-radius: 5px;
}

.error-hover {
  padding: 0.75rem 1rem 0.1rem 0;
  border-radius: 4px;
  color: var(--white);
}

.popover-card {
  padding-bottom: 0.75rem !important;
}

/* Filter styling */
.filter-container {
  display: flex;
  align-items: center;
}

.filter-select {
  width: fit-content !important;
  min-width: 15rem !important;
  margin: 0 0.75rem !important;
}

.filter-information-text {
  padding: 0 0.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
}

.space {
  margin-right: 0.25rem;
}
