.ant-segmented-item-selected {
  color: var(--primary-text-color) !important;
  border-radius: 5px !important;
}

.ant-segmented-item-selected .ant-segmented-item-label {
  color: var(--primary-text-color) !important;
}

.ant-segmented-item {
  text-align: inherit !important;
}

.ant-segmented-item-label {
  color: var(--grey5);
}

.ant-segmented {
  background-color: var(--grey2) !important;
  border-radius: 5px !important;
}

.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: var(--grey3) !important;
}
