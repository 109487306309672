.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  width: fit-content;
  border: 1px solid var(--grey4) !important;
  border-radius: 2px;
  padding: 0 0.3rem;
  cursor: pointer;
  color: var(--grey6);
}

.text-margin {
  margin-left: 0.25rem;
  font-weight: 400 !important;
}

.filled {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary3) !important;
  color: var(--primary-text-color);
}

.text-margin-filled {
  margin-right: 0.25rem;
  font-weight: 400 !important;
  color: var(--primary-text-color);
}

.dropdown-item-container {
  box-shadow: 0px 4px 8px var(--shadow);
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  background-color: var(--white);
  width: 10rem;
}

.item-container {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  cursor: pointer;
}

.item-container:hover {
  background-color: var(--primary1);
}

.item-text {
  margin-top: -0.75rem;
}

.search-data {
  display: flex;
  flex-direction: column;
}

.search-data-container {
  height: 12rem;
  overflow-y: scroll;
}

.search-input {
  padding: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid var(--grey4);
}

.plus-icon {
  font-size: 8px;
}

.new-tag-container {
  display: inline-flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  cursor: pointer;
  width: 100%;
  grid-gap: 0.5rem;
}

.new-tag-container:hover {
  background-color: var(--primary1);
}
